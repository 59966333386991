import axios from 'axios';
import https from 'https';
import { getCookie } from 'cookies-next';

export const BASE_API_URL = 'https://contest.lalau.ru/api/';

export const $api = axios.create({
    baseURL: BASE_API_URL,
    timeout: 60000,
    httpsAgent: new https.Agent({
        keepAlive: true,
    }),
});

$api.interceptors.request.use((config) => {
    if (!config.headers['apiKey']) config.headers['apiKey'] = getCookie('apiKey');

    return config;
});
